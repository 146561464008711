import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';

import { hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { getLatestInterval } from '../../helpers/date';
import { setWebsocketLastUpdate } from '../pages';

const shouldRefreshRecent = (recentTimeseries, latestInterval) => {
  let refresh = true;
  const lastRefresh = get(recentTimeseries, 'lastRefresh');
  if (lastRefresh) {
    refresh = lastRefresh?.isBefore(latestInterval);
  }
  if (refresh) {
    return true;
  }
  return false;
};

const shouldRefreshLastYear = (lastYearTimeseries, today) => {
  let refresh = true;
  const lastRefresh = get(lastYearTimeseries, 'lastRefresh');
  if (lastRefresh) {
    refresh = lastRefresh?.isBefore(today, 'day');
  }
  if (refresh) {
    return true;
  }
  return false;
};

const shouldRefreshPeaks = (peaks, today) => {
  let refresh = true;
  const lastRefresh = get(peaks, 'lastRefresh');
  if (lastRefresh) {
    refresh = lastRefresh?.isBefore(today, 'day');
  }
  if (refresh) {
    return true;
  }
  return false;
};

const refreshMeterTimeseries = createAsyncThunk(
  'timeseries/refreshMeterTimeseries',
  async (meterIds, { getState, dispatch, requestId }) => {
    const {
      data: _allTimeseries,
      lastYearData: _lastYearData,
      loading,
      currentRequestId,
    } = getState().timeseries;
    const allMeters = getState().meters.data;

    let allTimeseries = cloneDeep(_allTimeseries);
    let allLastYearData = cloneDeep(_lastYearData);
    if (!loading || requestId !== currentRequestId) {
      return;
    }

    try {
      let latestInterval = getLatestInterval();
      let today = dayjs();
      let updateRecentTimeseries = [];
      let updateLastYearData = [];
      meterIds.forEach((meterId) => {
        const meter = find(allMeters, { meter_id: meterId });
        if (!meter.type_) return;
        const recentMeterTimeseries = find(allTimeseries, {
          device_id: meter.meter_id,
          org_id: meter.org_id,
          data_type: 'recent',
        });
        const lastYearMeterData = find(allLastYearData, {
          meterId: meter.meter_id,
          orgId: meter.org_id,
        });
        if (shouldRefreshRecent(recentMeterTimeseries, latestInterval)) {
          updateRecentTimeseries.push({
            orgId: meter.org_id,
            meterId: meter.meter_id,
          });
        }
        if (shouldRefreshLastYear(lastYearMeterData, today)) {
          updateLastYearData.push({
            orgId: meter.org_id,
            meterId: meter.meter_id,
          });
        }
      });

      if (updateRecentTimeseries.length > 0) {
        console.info(
          `REFRESH TIMESERIES :: ${updateRecentTimeseries.length} ::`,
          dayjs().format('MM-DD HH:mm:ss')
        );
        let resolvedTimeseries = await Promise.all(
          map(updateRecentTimeseries, async ({ orgId, meterId }) => {
            const timeseries = await new WebAPIClient().GET(
              `/resource/timeseries/${orgId}/${meterId}`,
              { dataType: 'recent' }
            );
            return { ...timeseries, lastRefresh: dayjs() };
          })
        );

        each(resolvedTimeseries, (timeseries) => {
          const index = findIndex(allTimeseries, {
            device_id: timeseries.meter_id,
            data_type: timeseries.data_type,
          });
          if (index > -1) {
            allTimeseries[index] = timeseries;
          } else {
            allTimeseries.push(timeseries);
          }
        });

        dispatch(setWebsocketLastUpdate(dayjs()));
      }

      if (updateLastYearData.length > 0) {
        console.info(
          `REFRESH LAST YEAR TIMESERIES :: ${updateLastYearData.length} ::`,
          dayjs().format('MM-DD HH:mm:ss')
        );
        let resolvedTimeseries = await Promise.all(
          map(updateLastYearData, async ({ orgId, meterId }) => {
            const timeseries = await new WebAPIClient().GET(
              `/resource/last_year_timeseries/${orgId}/${meterId}`
            );
            return { data: timeseries, orgId, meterId, lastRefresh: dayjs() };
          })
        );

        each(resolvedTimeseries, (timeseries) => {
          const index = findIndex(allLastYearData, {
            meterId: timeseries.meterId,
            orgId: timeseries.orgId,
          });
          if (index > -1) {
            allLastYearData[index] = timeseries;
          } else {
            allLastYearData.push(timeseries);
          }
        });
      }

      return {
        data: allTimeseries,
        lastYearData: allLastYearData,
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const refreshMeterPeaks = createAsyncThunk(
  'timeseries/refreshMeterPeaks',
  async ({ meterId, start, end }, { getState, dispatch, requestId }) => {
    const timesteriesStore = getState().timeseries;
    const { peaks: _peaks, currentRequestId } = timesteriesStore;
    const allMeters = getState().meters.data;
    let allPeaks = cloneDeep(_peaks);

    if (requestId !== currentRequestId) {
      return;
    }

    try {
      let today = dayjs();
      const meter = find(allMeters, { meter_id: meterId });
      if (!meter.type_) return;

      const meterPeaks = find(allPeaks, (peak) => {
        return peak.meterId === meter.meter_id && peak.orgId === meter.org_id;
      });

      if (shouldRefreshPeaks(meterPeaks, today)) {
        console.info(`REFRESH PEAKS ::`, dayjs().format('MM-DD HH:mm:ss'));
        const peaks = await new WebAPIClient().GET(
          `/resource/timeseries/${meter.org_id}/${meterId}`,
          {
            dataType: 'peaks',
            start,
            end,
          }
        );

        const index = findIndex(allPeaks, {
          meterId: meterId,
          orgId: meter.org_id,
        });
        const item = {
          meterId: meterId,
          orgId: meter.org_id,
          peaks: peaks,
          lastRefresh: dayjs(),
        };
        if (index > -1) {
          allPeaks[index] = item;
        } else {
          allPeaks.push(item);
        }
      }
      return { peaks: allPeaks };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getPerformancePeaks = createAsyncThunk(
  'timeseries/getPerformancePeaks',
  async (orgId, { getState, dispatch, requestId }) => {
    const timesteriesStore = getState().timeseries;
    const { performancePeaks: _performancePeaks, currentRequestId } =
      timesteriesStore;
    const allMeters = getState().meters.data;
    let allPerformancePeaks = cloneDeep(_performancePeaks);

    if (requestId !== currentRequestId) {
      return;
    }

    try {
      const orgMeters = filter(allMeters, { org_id: orgId });
      let performancePeaks = await Promise.all(
        map(
          orgMeters,
          async (meter) =>
            await new WebAPIClient().GET(
              `/resource/timeseries/${meter.org_id}/${meter.meter_id}`,
              {
                dataType: 'performance_peaks',
              }
            )
        )
      );
      each(performancePeaks, (performancePeak) => {
        const index = findIndex(allPerformancePeaks, {
          meterId: performancePeak.meterId,
          orgId: performancePeak.orgId,
        });
        if (index > -1) {
          allPerformancePeaks[index] = performancePeak;
        } else {
          allPerformancePeaks.push(performancePeak);
        }
      });
      return { performancePeaks: allPerformancePeaks };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { refreshMeterTimeseries, refreshMeterPeaks, getPerformancePeaks };
